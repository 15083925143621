export const IMAGE_URL = 'https://d51h18g2kuzad.cloudfront.net';
export const ENVIRONMENT = 'prod';
export const WS_ENDPOINT = 'wss://appservices-ws.trustscience.com/clientconsole';
export const DOCS_URL = 'https://appservices.trustscience.com/docs';
export const S3_BUCKET = 'ts-application-services-static-websites';
export const LOGROCKET_APPID = '1uegt8/clientconsoleprod';
export const COGNITO_DOMAIN = 'auth.console.trustscience.com';
export const FLOWBUILDER_URL = 'https://web2.flowbuilder.trustscience.com';
export const FLOWBUILDER_API_URL = 'https://92uh0mkg2f.execute-api.us-west-2.amazonaws.com/';
export const LOGOUT_URL = 'https://trustsc.auth.us-west-2.amazoncognito.com/logout?client_id=67fb1fboflpsklu5t8f8uhrsc3&logout_uri={{=it.consoleSignOutUrl}}';
export const CURRENT_ENVIRONMENT_NAME = 'Production';
export const UAT_ENVIRONMENT_NAME = 'UAT';
