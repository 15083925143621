import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CardBody,
  Collapse,
} from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IncomeSourcesCard.module.scss';
import categoryLabels from '../../../config/banking-category-labels';
import { useModal } from '../../../hooks/useModal';
import CollapsibleCard from '../CollapsibleCard';
import ExpandCollapseIcon from '../../../components/ExpandCollapseIcon';
import IncomeTransactionsTable from './IncomeTransactionsTable';
import IncomeChart from './IncomeChart/IncomeChartView';
import capitalize from '../../../utils/capitalize';
import getSubCategories from '../../../utils/get-banking-sub-categories';
import api from '../../../services/api';
import getMonthlyIncome from './get-monthly-income';
import HelpMessage from './HelpMessage';

const blankToggleText = '\xa0\xa0\xa0';

const renderMonthlyAmount = (monthlyAmount) => (
  <span className="font-weight-bold">
    $
    {Math.round(monthlyAmount)}
    <wbr />
    /month
  </span>
);

const formatPayFrequency = (payFrequency) => {
  if (!payFrequency) {
    return 'Irregular/Unknown';
  }
  return payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
};

const payFrequencyToColor = (payFrequency) => {
  if (!payFrequency) {
    return 'danger';
  }
  if (payFrequency.startsWith('days')) {
    return 'warning';
  }
  return 'success';
};

function StabilityIndicator({ stability }) {
  let color = 'danger';
  if (stability === 'high') {
    color = 'success';
  } else if (stability === 'medium') {
    color = 'warning';
  }
  return (
    <span className={`font-weight-bold text-${color}`}>
      {capitalize(stability)}
    </span>
  );
}

function SubCategory({ subCategoryList }) {
  const [currentCategory, ...subCategories] = subCategoryList;

  const currentCategoryLabel = categoryLabels[currentCategory];
  if (!currentCategoryLabel) {
    console.error(`Unknown subcategory: ${currentCategory}`);
    return null;
  }

  const hasSubCategories = subCategories.length > 0;

  return (
    <div className="d-inline">
      {currentCategoryLabel}
      {hasSubCategories && (
        <div className="ml-2">
          <span className="font-weight-bold">{'→ '}</span>
          <SubCategory subCategoryList={subCategories} />
        </div>
      )}
    </div>
  );
}

function IncomeSource({
  sourceData, isIncome, selected, toggleSelected, useGrossIncome,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const monthlyIncome = getMonthlyIncome(sourceData, useGrossIncome);

  return (
    <div className={`card my-2 ${selected ? styles.selectedCard : ''}`}>
      <div className="card-body">
        <div className="d-flex">
          <div className={`d-flex align-items-center mr-3 ${styles.incomeSourceCollapse}`}>
            <input type="checkbox" checked={selected} onChange={toggleSelected} className={styles.selectIncomeSourceCheckbox} />
          </div>
          <div className={`flex-grow-1 ${styles.clickable}`} onClick={toggleSelected} aria-hidden="true">
            <div className="row">
              <div className="col-7 col-xl-8">
                <div className="row m-1">
                  <h4>
                    {sourceData.description.toUpperCase()}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-xl-3">
                      <div className="col-12 col-xl-4 text-left">
                        {'First Occurrence: '}
                      </div>
                      <div className="col-12 col-xl-8 text-left font-weight-bold">
                        {sourceData.earliestDate}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-4 text-left">
                        {'Last Occurrence: '}
                      </div>
                      <div className="col-12 col-xl-8 text-left font-weight-bold">
                        {sourceData.latestDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-xl-4 text-left">
                        {isIncome ? 'Income category: ' : 'Category: '}
                      </div>
                      <div className="col-12 col-xl-8 text-left font-weight-bold">
                        <SubCategory subCategoryList={getSubCategories(sourceData.category)} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-4 text-left">
                        Stability:
                      </div>
                      <div className="col-12 col-xl-8 text-left font-weight-bold">
                        <StabilityIndicator stability={sourceData.incomeSourceStabilityLabel} />
                      </div>
                    </div>
                  </div>
                </div>
                {sourceData.alerts.length > 0
            && (
            <div className="row">
              <div className="col-12 text-left font-weight-bold">Alerts:</div>
              <div className="col-12">
                {sourceData.alerts.map((alert) => (
                  <div
                    key={alert}
                    className="text-left text-danger font-weight-bold"
                  >
                    {alert}
                  </div>
                ))}
              </div>
            </div>
            )}
              </div>
              <div className="col-5 col-xl-4">
                <div>
                  <h4 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 text-left">
                        {'Net Income: '}
                        <br />
                        {monthlyIncome <= 0 ? <span className="font-weight-bold">Unknown</span> : (
                          renderMonthlyAmount(getMonthlyIncome(sourceData, false))
                        )}
                      </div>
                    </div>
                  </h4>
                  <h5 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 text-left">
                        {'Estimated Gross Income: '}
                        <br />
                        {monthlyIncome <= 0 ? <span className="font-weight-bold">Unknown</span> : (
                          renderMonthlyAmount(getMonthlyIncome(sourceData, true))
                        )}
                      </div>
                    </div>
                  </h5>
                </div>
                {isIncome && (
                <div>
                  <h5 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 text-left">
                        {'Frequency: '}
                      </div>
                      <div className="col-12 text-left">
                        <span className={`text-${payFrequencyToColor(sourceData.payFrequency)}`}>
                          {formatPayFrequency(sourceData.payFrequency)}
                        </span>
                      </div>
                    </div>
                  </h5>
                </div>
                )}
                <div>
                  <h5 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 text-left">
                        {'Next payment: '}
                      </div>
                      <div className="col-12 text-left align-self-center">
                        <span className={sourceData.nextPaymentDate && 'font-weight-bold'}>
                          {sourceData.nextPaymentDate ?? 'N/a'}
                        </span>
                      </div>
                    </div>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex align-items-center ${styles.incomeSourceCollapse}`}>
            <ExpandCollapseIcon
              isCollapsed={isCollapsed}
              toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        </div>
        <Collapse isOpen={!isCollapsed} className={styles.printable}>
          <IncomeTransactionsTable transactions={sourceData.transactions ?? []} />
        </Collapse>
      </div>
    </div>
  );
}

function SectionHeader({ title }) {
  return (
    <div className="row">
      <div className="col">
        <h4 className="text-left m-1 mt-4">{title}</h4>
      </div>
    </div>
  );
}

function StatInfoSummary({
  name, value, sources, toggleSelected, checkBoxClass, checkBoxKey, checkBoxId, useGrossIncome, checked,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggle = () => toggleSelected(checkBoxKey);
  return (
    <div>
      <h5>
        <div className={styles.lineCheckboxLabelWrapper}>
          <input
            id={checkBoxId}
            type="checkbox"
            checked={checked}
            onChange={toggle}
            className={checkBoxClass}
          />
          <div>
            <label htmlFor={checkBoxId}>
              {' '}
              {name}
              :
            </label>
            <div className="d-flex align-items-center">
              <button type="button" onClick={toggle} className="border-0 bg-transparent">
                {renderMonthlyAmount(value)}
              </button>
              <div className="d-inline mt-n1">
                <ExpandCollapseIcon
                  isCollapsed={isCollapsed}
                  toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
              </div>
            </div>
          </div>
        </div>
      </h5>
      <Collapse isOpen={!isCollapsed} className={styles.printable}>
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Source</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((sourceData) => (
              <tr key={sourceData.key} className={styles.selectedIncomeSource}>
                <th>
                  {sourceData.description.toUpperCase()}
                </th>
                <th>
                  $
                  {Math.round(getMonthlyIncome(sourceData, useGrossIncome))}
                </th>
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td>
                $
                {Math.round(value)}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
}

// SvdO 2024-09-30: Description is not a great field join, but DE will add a key in DE-5145
const addSourceKey = (sourceData) => ({ ...sourceData, key: sourceData.description });

export default function IncomeSourcesCardView({
  clientReports, currentEmployment, requestId, isUat,
}) {
  const { products } = clientReports?.[0] ?? {};

  const bank844Data = products?.find((product) => product.productCode === 'BANK-844');

  const highConfidenceIncome = useMemo(() => bank844Data?.bankingIncomeSources.filter((source) => source.highConfidence).map(addSourceKey), [bank844Data]);
  const otherIncome = useMemo(() => bank844Data?.bankingIncomeSources.filter((source) => !source.highConfidence).map(addSourceKey), [bank844Data]);
  const otherCredits = useMemo(() => (bank844Data?.otherCreditSources ?? []).map(addSourceKey), [bank844Data]);

  const [chartIncomes, setChartIncomes] = useState([]);
  const [selectedSources, setSelectedSources] = useState({});
  const [useGrossIncome, setUseGrossIncome] = useState(false);

  useEffect(() => {
    const getChartData = async () => {
      const { success, incomes } = await api('GetIncomeChartData', {
        isUat,
        requestId,
      });
      if (!success) {
        console.error('Failed to get income chart data');
        return;
      }
      const incomesWithKey = incomes?.map(addSourceKey) ?? [];
      setChartIncomes(incomesWithKey);
      const lineEntries = [
        ['HighConfidenceIncomeLine', true],
        ['HighConfidenceAndOtherIncomeLine', true],
      ];
      setSelectedSources(Object.fromEntries([
        ...incomesWithKey.map((source) => [source.key, true]),
        ...highConfidenceIncome.map((source) => [source.key, true]),
        ...otherIncome.map((source) => [source.key, true]),
        ...otherCredits.map((source) => [source.key, false]),
        ...lineEntries,
      ]));
    };
    if (bank844Data) {
      getChartData();
    }
  }, [bank844Data, highConfidenceIncome, otherIncome, otherCredits, isUat, requestId, setSelectedSources]);

  const monthlyIncomesReducer = useCallback((acc, source) => acc + getMonthlyIncome(source, useGrossIncome), [useGrossIncome]);

  const { showModal } = useModal();

  if (!bank844Data || bank844Data.productStatus === 'error') {
    return null;
  }

  const toggleSelected = (key) => {
    setSelectedSources((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const showHelp = () => {
    showModal({
      header: 'Help',
      body: <HelpMessage />,
      className: styles.helpModal,
    });
  };

  const isSelectedFiltered = (source) => selectedSources[source.key];

  const selectedHighConfidenceIncome = highConfidenceIncome.filter(isSelectedFiltered);
  const selectedOtherIncome = otherIncome.filter(isSelectedFiltered);

  const sumHighConfidenceMonthlyIncomes = selectedHighConfidenceIncome.reduce(monthlyIncomesReducer, 0);
  const sumMonthlyIncomes = selectedOtherIncome.reduce(monthlyIncomesReducer, sumHighConfidenceMonthlyIncomes);

  const selfReportedGrossIncome = currentEmployment?.monthlyIncomeGross ?? null;
  const selfReportedNetIncome = currentEmployment?.monthlyIncomeNet ?? null;
  const hasSelfReportedIncome = selfReportedGrossIncome !== null || selfReportedNetIncome !== null;

  return (
    <CollapsibleCard title="Income Sources" collapseKey="incomeSources">
      <CardBody>
        <div className="row">
          <div className="col-9">
            <IncomeChart
              incomes={chartIncomes}
              selectedSources={selectedSources}
              toggleSelected={toggleSelected}
              highConfidenceTotal={sumHighConfidenceMonthlyIncomes}
              highConfidenceAndOtherTotal={sumMonthlyIncomes}
              useGrossIncome={useGrossIncome}
            />
            <h3 className="text-left m-1 mt-4 text-primary text-center">Recent Income & Credit Sources</h3>
            <SectionHeader title={highConfidenceIncome.length ? 'High Confidence Income:' : 'No High Confidence Income Sources'} />
            {highConfidenceIncome.map((sourceData) => (
              <IncomeSource
                key={sourceData.key}
                sourceData={sourceData}
                isIncome
                selected={!!selectedSources[sourceData.key]}
                toggleSelected={() => toggleSelected(sourceData.key)}
                useGrossIncome={useGrossIncome}
              />
            ))}
            <SectionHeader title={otherIncome.length ? 'Other Income:' : 'No Other Income Sources'} />
            {otherIncome.map((sourceData) => (
              <IncomeSource
                key={sourceData.key}
                sourceData={sourceData}
                isIncome
                selected={!!selectedSources[sourceData.key]}
                toggleSelected={() => toggleSelected(sourceData.key)}
                useGrossIncome={useGrossIncome}
              />
            ))}
            <SectionHeader title={otherCredits.length ? 'Other Credits:' : 'No Other Credits'} />
            {otherCredits.map((sourceData) => (
              <IncomeSource
                key={sourceData.key}
                sourceData={sourceData}
                isIncome={false}
                selected={!!selectedSources[sourceData.key]}
                toggleSelected={() => toggleSelected(sourceData.key)}
                useGrossIncome={useGrossIncome}
              />
            ))}
          </div>
          <div className="col-3 px-2">
            <div className={`text-left ${styles.stickyStatsBox}`}>
              <div className="mb-4">
                <div className="text-right">
                  <button
                    className={`bg-transparent border-0 m-0 mb-2 mt-n1 p-1 ${styles.helpButton}`}
                    aria-label="Income Sources Help"
                    type="button"
                    onClick={showHelp}
                  >
                    <FontAwesomeIcon
                      icon="question-circle"
                      color="grey"
                      size="lg"
                    />
                  </button>
                </div>
                {hasSelfReportedIncome ? (
                  <>
                    <h4>Self-reported Income</h4>
                    {selfReportedGrossIncome && (
                      <div className="ml-2">
                        <h5>Gross Income:</h5>
                        <h5 className="ml-4">
                          {renderMonthlyAmount(selfReportedGrossIncome)}
                        </h5>
                      </div>
                    )}
                    {selfReportedNetIncome && (
                      <div className="ml-2">
                        <h5>Net Income:</h5>
                        <h5 className="ml-4">
                          {renderMonthlyAmount(selfReportedNetIncome)}
                        </h5>
                      </div>
                    )}
                  </>
                ) : <h4>Self-reported Income Not Provided</h4>}
              </div>
              <div className="text-center rounded border pt-3 mb-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="h4" htmlFor="grossNetIncomeToggle">Net</label>
                <div className="d-inline mt-n2">
                  <Toggle
                    id="grossNetIncomeToggle"
                    active={useGrossIncome}
                    on={blankToggleText}
                    off={blankToggleText}
                    size="xs"
                    onstyle="success"
                    handlestyle="default"
                    offstyle="custom"
                    className="mt-n2 mx-2"
                    onClick={(value) => setUseGrossIncome(value)}
                  />
                </div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="h4" htmlFor="grossNetIncomeToggle">Gross</label>
              </div>
              <h4>Selected Income</h4>
              <StatInfoSummary
                name="High Confidence Income"
                value={sumHighConfidenceMonthlyIncomes}
                sources={selectedHighConfidenceIncome}
                toggleSelected={toggleSelected}
                checkBoxClass={styles.selectHighConfidenceLineCheckbox}
                checkBoxKey="HighConfidenceIncomeLine"
                checkBoxId="highConfidenceIncomeLineCheckbox"
                checked={!!selectedSources.HighConfidenceIncomeLine}
                useGrossIncome={useGrossIncome}
              />
              <StatInfoSummary
                name="All Income"
                value={sumMonthlyIncomes}
                sources={[...selectedHighConfidenceIncome, ...selectedOtherIncome]}
                toggleSelected={toggleSelected}
                checkBoxClass={styles.selectHighAndOtherConfidenceLineCheckbox}
                checkBoxKey="HighConfidenceAndOtherIncomeLine"
                checkBoxId="highAndOtherConfidenceLineCheckbox"
                checked={!!selectedSources.HighConfidenceAndOtherIncomeLine}
                useGrossIncome={useGrossIncome}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </CollapsibleCard>
  );
}
