export const defaultCollapseConfig = {
  products: false,
  incomeSources: false,
  bankingSummary: false,
  customAttributes: false,
  bankingAccounts: true,
  bureauReport: true,
  smartConsent: true,
  applicationSummary: true,
  developerInfo: true,
  callbackHistory: true,
};

const getCollapseConfig = (config) => ({
  ...defaultCollapseConfig,
  ...(config.consoleScoringDetailsCollapse ?? {}),
});

export default getCollapseConfig;
