import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import api from '../../services/api';
import ClientLogo from '../../components/ClientLogo';
import Loader from '../../components/Loader';
import Report from './Report';
import mapFunctions from '../../utils/map-functions';

import './home.scss';

const functionsMapper = {
  percentageFormatter: (val) => `${(100 * val).toFixed(2)} %`,
};

function Home(props) {
  const [data, setData] = useState({});
  const {
    logo, displayName, dashboard, features,
  } = props.clientInfo;
  const { clientId } = props;

  useEffect(() => {
    async function fetchData() {
      if (
        features.dashboard
      && dashboard?.reports
      && Object.keys(dashboard.reports).some((key) => dashboard.reports[key])
      ) {
        const response = await api('GetChartsData');
        const hydratedChartsData = mapFunctions(response, functionsMapper);
        setData(hydratedChartsData);
      } else {
        setData({ notAuthorized: true });
      }
    }
    fetchData();
  }, [dashboard, features]);

  return (
    <>
      <div className="home-container">
        <div className="trade-mark-slogan">
          Welcome to
          <span className="green-text">Credit Bureau+™/Credit Bureau 2.0®</span>
        </div>
        <ClientLogo clientId={clientId} companyLogo={logo} companyName={displayName} />
      </div>
      <div className="print-header">
        <ClientLogo clientId={clientId} companyLogo={logo} companyName={displayName} />
        <div className="centered">
          <p>{displayName}</p>
          <p>
            Generated on
            {' '}
            {new Date(Date.now()).toLocaleString()}
          </p>
        </div>
      </div>
      {!Object.keys(data).length && <Loader />}
      {data.noClientData && (
        <p className="d-flex justify-content-center">No metrics data available.</p>
      )}
      {data.reportTableData && (
        <>
          <Report data={data.reportTableData} />
          <div className="apex">
            {data.chartsData?.map((chart) => (
              <ApexCharts
                className="chart"
                key={chart.options.title.text}
                type={chart.type}
                options={chart.options}
                series={chart.series}
              />
            ))}
          </div>
        </>
      )}
      <div className="print-only-doc-footer">
        Trust Science
        <sup>®</sup>
        {' '}
        monthly report for
        {' '}
        {displayName}
      </div>
    </>
  );
}

export default Home;
