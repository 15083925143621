import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import api from '../../services/api';
import getCurrentAuthSession from '../../utils/get-current-auth-session';
import SITE_PATHS, { USE_CASE_TO_SITE_PATHS } from '../../config/sitePaths';

import Loader from '../../components/Loader';
import {
  getRequestDetails,
  getReport,
  getBankingStatus,
} from '../../services/scoring-request-services';

import {
  getActionMenu,
} from '../../services/workflow-services';

import ScoreCard from '../../components/ScoreCard';
import BankingInfoCard from './BankingInfoCard';
import BankingAccountCard from './BankingAccountCard';
import ProductsReport from './ProductsReport';
import CustomAttributes from './CustomAttributes';
import BureauReport from './BureauReport';
import DeveloperView from './DeveloperView';
import TransUnionButton from './TransUnionButton';
import BankingButton from './BankingButton';
import FlowBuilderGenerator from './FlowBuilderGenerator';
import SmartConsentCard from './SmartConsentCard';
import ActionMenu from './ActionMenu';
import ScoringRequestInputSummary from '../../components/ScoringRequestInputSummary';
import { payloadToFormState, payloadToSummaryState } from '../../utils/remapScoringPayload';
import sortClientReports from '../../utils/sort-client-reports';
import { withUseEnabledFeatures } from '../../hooks/useEnabledFeatures';
import CallbackHistoryView from './CallbackHistoryView';

import styles from './ScoringRequestDetails.module.scss';
import EnvironmentDropDownFixed from '../../components/DropDown/EnvironmentDropDownFixed';
import { BankingAnnotationsProvider } from './hooks/useBankingAnnotations';
import { IsCollapsedProvider } from './hooks/useIsCollapsed';
import IncomeSourcesCard from './IncomeSourcesCard';
import DownloadDropdown from './DownloadDropdown';

const safeParseInt = (value, defaultValue) => {
  try {
    return parseInt(value, 10);
  } catch {
    return defaultValue;
  }
};

class ScoringRequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      details: null,
      user: null,
      developer: false,
      bankingAnalysisReport: null,
      bankingTransactions: null,
      bankingAnnotations: [],
      showRescoreError: false,
      cannotPerformRescore: false,
      transactionFilter: '90',
      bankingStatus: {
        accountLogin: false,
      },
      menu: [],
      requestId: this.props.match?.params?.requestId?.toLowerCase(),
    };
  }

  async componentDidMount() {
    if (!this.state.requestId) {
      this.setState({ loading: false, error: true });
      return;
    }

    const { requestId } = this.state;
    const { user } = await getCurrentAuthSession();
    if (user['cognito:groups']?.includes('developer')) {
      this.setState({ developer: true });
    }
    this.setState({ user });

    getRequestDetails(requestId, this.isUat())
      .then((details) => {
        const cannotPerformRescore = details.scoring.error
          || details.control.error
          || !details.scoring.useCase;

        this.setState({
          loading: false,
          error: false,
          details: {
            ...details,
            reports: sortClientReports(details.reports),
          },
          cannotPerformRescore,
        });
      })
      .catch((err) => {
        console.error('Error on retrieving request details', err);
        this.setState({ loading: false, error: true });
      });

    getActionMenu(requestId, this.isUat())
      .then((menu) => {
        this.setState({ menu });
      });

    getBankingStatus(requestId, this.isUat())
      .then((bankingStatus) => {
        this.setState({ bankingStatus });
      });

    this.loadReportFromWarehouse('bureauReport', this.props.clientConfig.features);
    this.loadBankingView(this.props.clientConfig.features);
  }

  loadBankingView = (features) => {
    const reportName = 'bankingAnalysisReport';
    const { requestId } = this.state;
    if (!requestId || !features[reportName]) return;
    getReport(requestId, reportName, this.isUat(), { skipLoadBankingView: features.skipLoadBankingView })
      .then((report) => {
        if (!report) return;
        this.setState({ [reportName]: report });
        this.loadTransactionsView(requestId, this.state.transactionFilter, report.accounts);
      })
      .catch((err) => {
        console.warn(`Banking report and analysis not available for ${requestId}`, err);
      });
    api('GetBankingAnnotations', { requestId, isUat: this.isUat() }).then((annotationObj) => {
      this.setState({ bankingAnnotations: annotationObj.data });
    });
  };

  loadReportFromWarehouse = (reportName, features, options = {}) => {
    const { requestId } = this.state;
    if (!requestId || !features[reportName]) return;
    getReport(requestId, reportName, this.isUat(), options)
      .then((report) => {
        if (!report) return;
        this.setState({ [reportName]: report });
      })
      .catch((err) => {
        console.warn(`${reportName} not available for ${requestId}`, err);
      });
  };

  loadTransactionsView = (requestId, transactionFilter, accounts) => {
    const filterDays = safeParseInt(transactionFilter, 90);
    accounts.forEach((account) => {
      const transactionCutoffDate = new Date(account.transactionCutoffDate);
      transactionCutoffDate.setDate(transactionCutoffDate.getDate() - filterDays);
      const filterCutoffDateKey = transactionCutoffDate.toISOString().split('-').slice(0, 2).join('');
      const queryForTransactions = [].concat(account.additionalTransactions)
        .filter((x) => !!x)
        .filter((dateKey) => dateKey >= filterCutoffDateKey);
      if (!queryForTransactions.length) return Promise.resolve(false);
      return getReport(requestId, 'bankingTransactions', this.isUat(), {
        accounts: [{
          id: account.id,
          additionalTransactions: queryForTransactions,
        }],
      })
        .then((additionalTransactions) => {
          if (!additionalTransactions) return;
          this.setState(({ bankingTransactions }) => ({
            bankingTransactions: {
              ...bankingTransactions,
              ...additionalTransactions,
            },
          }));
        })
        .catch((err) => {
          console.warn(`Banking transactions not available for ${requestId}`, err);
        });
    });
  };

  handleTransactionFilterChanged = (transactionFilter) => {
    if (transactionFilter === this.state.transactionFilter) return;
    this.setState({ transactionFilter });
    this.loadTransactionsView(this.state.requestId, transactionFilter, this.state.bankingAnalysisReport.accounts);
  };

  renderRequestSummary = () => {
    let requestSummaryData = {};
    const { details } = this.state;

    if (details) {
      requestSummaryData = payloadToSummaryState(details, this.props.clientConfig.customApplicationFormFields);
    }

    return (
      <ScoringRequestInputSummary
        tsBankingURL={details.smartConsentUrl}
        requestSummaryData={requestSummaryData}
        clientConfig={this.props.clientConfig}
      />
    );
  };

  startRescoreWorkflow = () => {
    const { requestId } = this.props.match.params;
    const { details } = this.state;
    const { useCase } = details.scoring;
    let pathname = USE_CASE_TO_SITE_PATHS[useCase];
    let requestSummaryData = {};

    if (details) {
      requestSummaryData = payloadToFormState(details, this.props.clientConfig.customApplicationFormFields);

      if (details.control && details.control.sendToAMS) {
        pathname = SITE_PATHS.AMS_FORM_START;
      }
    }

    this.props.history.push(
      {
        pathname,
        state: {
          rescoreRequestId: requestId,
          ...requestSummaryData,
          control: details.control,
          scoring: details.scoring,
        },
        search: this.props?.location?.search,
      },
    );
  };

  toggleRescoreErrorModal = () => {
    this.setState((prevState) => ({ showRescoreError: !prevState.showRescoreError }));
  };

  isUat() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('env') === 'uat';
  }

  renderActionsBlock() {
    if (!(this.props.clientConfig.features)) return null;

    const renderRescoreButton = () => {
      if (!this.props.clientConfig.features.performRescore) {
        return null;
      }

      let label = 'Rescore';
      if (this.state.details && this.state.details.person) {
        label += ` ${this.state.details.person.firstName} ${this.state.details.person.lastName}`;
      }

      return (
        <Button
          color={this.state.cannotPerformRescore ? 'disabled' : 'primary'}
          onClick={() => {
            if (this.state.cannotPerformRescore) {
              this.setState({ showRescoreError: true });
              return;
            }
            this.startRescoreWorkflow();
          }}
        >
          {label}
        </Button>
      );
    };

    const renderGenerateFlowBuilder = () => {
      if (!this.props.clientConfig.features.enableFlowbuilderControls) {
        return null;
      }

      return (<FlowBuilderGenerator requestId={this.props.match.params.requestId} isUat={this.isUat()} />);
    };

    const renderRescoreErrorModal = () => (
      <Modal
        isOpen={this.state.showRescoreError}
        toggle={this.toggleRescoreErrorModal}
        onClosed={() => this.setState({ showRescoreError: false })}
      >
        <ModalHeader toggle={this.toggle}>Cannot Perform Rescore</ModalHeader>
        <ModalBody>
          The console cannot proceed with the rescore workflow. Please contact our support team for further assistance.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleRescoreErrorModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );

    return (
      <div className={styles.actions}>
        {renderGenerateFlowBuilder()}
        {renderRescoreButton()}
        {renderRescoreErrorModal()}
        <BankingButton
          requestId={this.props.match.params.requestId}
          features={this.props.clientConfig.features}
          bankingStatus={this.state.bankingStatus}
          clientId={this.props.clientId}
          isUat={this.isUat()}
        />
        <TransUnionButton
          requestId={this.props.match.params.requestId}
          features={this.props.clientConfig.features}
          integration={this.props.clientConfig.integration}
          clientId={this.props.clientId}
          isUat={this.isUat()}
        />
        <ActionMenu
          requestId={this.props.match.params.requestId}
          menu={this.state.menu}
          isUat={this.isUat()}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const scoringRequestDetailsAccessUAT = this.props.useEnabledFeaturesUatProp.scoringRequestDetails;
    const scoringRequestDetailsAccessProd = this.props.useEnabledFeaturesProdProp.scoringRequestDetails;

    if (this.isUat() && !scoringRequestDetailsAccessUAT) {
      return (
        <div className={styles.error}>
          You do not have access to view testing (UAT) requests.
        </div>
      );
    }
    if (!this.isUat() && !scoringRequestDetailsAccessProd) {
      return (
        <div className={styles.error}>
          You do not have access to view production requests.
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className={styles.error}>
          There was an error retrieving request details for&nbsp;
          {this.props.match.params.requestId}
          .
        </div>
      );
    }

    const details = this.state.details || {};
    const loanInfoObj = {
      ...details.loanApplication,
      customerId: details.loanApplication.clientCustomerId,
      loanId: details.loanApplication.clientLoanReferenceId,
    };

    return (
      <IsCollapsedProvider clientConfig={this.props.clientConfig}>
        <div className="row">
          <div className="col-12">
            <div className="float-right mx-2">
              <DownloadDropdown
                requestId={this.state.requestId}
                isUat={this.isUat()}
                bankingAvailable={this.state.bankingAnalysisReport?.accounts?.length > 0}
              />
            </div>
            {scoringRequestDetailsAccessUAT && <EnvironmentDropDownFixed environment={this.isUat() ? 'uat' : 'prod'} />}
          </div>
        </div>
        <ScoreCard
          applicant={details.person}
          clientReports={details.reports}
          loanInfo={loanInfoObj}
          scoring={details.scoring}
          features={this.props.clientConfig.features}
        />
        {this.renderActionsBlock()}
        <ProductsReport
          clientReports={details.reports}
        />
        <CustomAttributes
          clientReports={details.reports}
        />
        <IncomeSourcesCard
          clientReports={details.reports}
          currentEmployment={details.currentEmployment}
          requestId={this.state.requestId}
          isUat={this.isUat()}
        />
        <BankingInfoCard
          features={this.props.clientConfig.features}
          bankingData={this.state.bankingAnalysisReport}
          bankingStatus={this.state.bankingStatus}
          applicant={this.state.details.person}
        />
        <BankingAnnotationsProvider
          bankingAnnotations={this.state.bankingAnnotations}
          addAnnotation={(annotation) => {
            this.setState(({ bankingAnnotations }) => ({
              bankingAnnotations: [...bankingAnnotations, annotation],
            }));
          }}
          requestInfo={{
            requestId: this.state.requestId,
            clientId: this.props.clientId,
          }}
          user={this.state.user}
        >
          <BankingAccountCard
            accounts={this.state.bankingAnalysisReport?.accounts}
            transactions={this.state.bankingTransactions}
            transactionFilter={this.state.transactionFilter}
            onTransactionFilterChange={this.handleTransactionFilterChanged}
          />
        </BankingAnnotationsProvider>
        <BureauReport
          bureauReport={this.state.bureauReport}
        />
        <SmartConsentCard
          features={this.props.clientConfig.features}
          bankingStatus={this.state.bankingStatus}
          requestId={this.props.match.params.requestId}
          tsBankingURL={this.state.details.smartConsentUrl}
          isUat={this.isUat()}
          isDeveloper={this.state.developer}
          person={this.state.details.person}
        />
        {this.renderRequestSummary()}
        {
          this.state.developer ? (
            <>
              <DeveloperView
                details={this.state.details}
              />
              <CallbackHistoryView
                features={this.props.clientConfig.features}
                requestId={this.props.match.params.requestId}
                isUat={this.isUat()}
              />
            </>
          )
            : null
        }

      </IsCollapsedProvider>
    );
  }
}

export default withUseEnabledFeatures(ScoringRequestDetails);
