import React, { useContext, useState } from 'react';
import {
  Modal as ReactstrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

const ModalContext = React.createContext();

export function ModalProvider({ children }) {
  const [modalState, setModalState] = useState({
    header: 'Header',
    body: 'Body',
    isOpen: false,
    onCloseClicked: undefined,
    buttons: [],
    className: '',
  });

  const hideModal = () => {
    setModalState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const showModal = ({
    header, body, onCloseClicked, buttons, className,
  }) => {
    setModalState({
      header,
      body,
      isOpen: true,
      onCloseClicked,
      buttons: buttons || [],
      className: className || '',
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{
      modalState,
      hideModal,
      showModal,
    }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

function Modal() {
  const {
    modalState,
    hideModal,
  } = useModal();

  const onCloseClicked = modalState.onCloseClicked || hideModal;
  const { buttons } = modalState;

  return (
    <ReactstrapModal isOpen={modalState.isOpen} toggle={onCloseClicked} className={modalState.className}>
      <ModalHeader>{modalState.header}</ModalHeader>
      <ModalBody>{modalState.body}</ModalBody>
      <ModalFooter>
        {buttons.length > 0 ? buttons.map((button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Button key={`modalButton-${index}`} color={button.color} onClick={button.onClick}>{button.text}</Button>
        )) : <Button color="secondary" onClick={onCloseClicked}>OK</Button>}
      </ModalFooter>
    </ReactstrapModal>
  );
}

// Allows you to use the useModal hook to be used in (legacy) class components
// eslint-disable-next-line react/jsx-props-no-spreading
export const withUseModal = (Component) => React.forwardRef((props, ref) => <Component ref={ref} {...props} useModalProp={useModal()} />);

export default useModal;
