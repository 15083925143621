import React from 'react';
import ApexChart from 'react-apexcharts';

import styleVariables from '../../../../styles/variables.scss';
import getMonthlyIncome from '../get-monthly-income';

const parseAsUTCDateString = (dateString) => new Date(`${dateString}T00:00:00.000Z`);

/**
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Array<string>}
 */
const getAllMonthDuringPeriod = (startDate, endDate) => {
  const startMonth = parseAsUTCDateString(`${startDate.toISOString().slice(0, 7)}-01`);
  const endMonth = parseAsUTCDateString(`${endDate.toISOString().slice(0, 7)}-01`);

  const months = [];
  const currentDate = startMonth;
  while (currentDate <= endMonth) {
    months.push(currentDate.toISOString().slice(0, 7));
    currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
  }
  return months;
};

const getAnnotation = ({ y, text, color }) => ({
  y,
  strokeDashArray: [3, 3],
  borderWidth: 3,
  borderColor: color,
  label: {
    borderColor: color,
    style: {
      color: '#fff',
      background: color,
      fontSize: '10px',
    },
    textAnchor: 'start',
    position: 'left',
    text,
  },
});

const getStyleOptionsWithFontSize = (fontSize) => ({
  dataLabels: {
    style: {
      fontSize,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: fontSize + 2,
      },
    },
  },
  plotOptions: {
    bar: {
      dataLabels: {
        total: {
          style: {
            fontSize: fontSize + 2,
          },
        },
      },
    },
  },
});

/**
 * @param {Array} sources
 * @param {Object} selectedSources
 */
const getChartData = (incomes, selectedSources, useGrossIncome) => {
  const allTransactions = incomes.flatMap((source) => source.transactions ?? []);
  const anyTransactionDate = parseAsUTCDateString(allTransactions[0]?.date);
  const firstTransactionDate = allTransactions.reduce((acc, transaction) => {
    const date = parseAsUTCDateString(transaction.date);
    return date < acc ? date : acc;
  }, anyTransactionDate);
  const lastTransactionDate = allTransactions.reduce((acc, transaction) => {
    const date = parseAsUTCDateString(transaction.date);
    return date > acc ? date : acc;
  }, anyTransactionDate);
  const sortedMonths = getAllMonthDuringPeriod(firstTransactionDate, lastTransactionDate);

  const series = incomes.map((sourceData) => {
    const sourceTransactions = sourceData.transactions ?? [];

    const transactionAmountsPerMonth = sortedMonths.map((month) => sourceTransactions
      .filter((transaction) => transaction.date.slice(0, 7) === month)
      .reduce((acc, transaction) => acc + transaction.credit, 0)).map(
      (amount) => getMonthlyIncome({
        monthlyIncome: amount,
        gross_income_multiplier: sourceData.gross_income_multiplier,
      }, useGrossIncome),
    );

    const incomeTitle = sourceData.description.toUpperCase();

    return {
      name: incomeTitle,
      key: sourceData.key,
      data: transactionAmountsPerMonth,
      type: 'column',
      hidden: !selectedSources[sourceData.key],
    };
  });
  return { series, sortedMonths };
};

function IncomeChart({
  incomes, selectedSources, toggleSelected, highConfidenceTotal, highConfidenceAndOtherTotal, useGrossIncome,
}) {
  if (!incomes || !incomes.length) {
    return null;
  }

  const { series, sortedMonths } = getChartData(incomes, selectedSources, useGrossIncome);

  const annotations = [];
  if (selectedSources.HighConfidenceIncomeLine) {
    annotations.push(getAnnotation({
      y: highConfidenceTotal,
      color: styleVariables.primaryColor,
      text: 'High Confidence Income Selected',
    }));
  }
  if (selectedSources.HighConfidenceAndOtherIncomeLine && Math.round(highConfidenceAndOtherTotal) !== Math.round(highConfidenceTotal)) {
    annotations.push(getAnnotation({
      y: highConfidenceAndOtherTotal,
      color: styleVariables.uatColor,
      text: 'All Income Selected',
    }));
  }

  const maxMonthSum = Math.max(...series.reduce((acc, source) => sortedMonths.map(
    (month, index) => acc[index] + source.data[index],
  ), sortedMonths.map(() => 0)));
  const maxMonthSumRounded = Math.ceil(maxMonthSum / 1000) * 1000;

  return (
    <ApexChart
      type="bar"
      options={{
        title: {
          text: 'Monthly Income',
        },
        colors: [
          '#003f5c',
          '#58508d',
          '#bc5090',
          '#ff6361',
          '#ffa600',
        ],
        tooltip: {
          theme: 'dark',
        },
        chart: {
          type: 'bar',
          stacked: true,
          // Disable menus and controls on the graph
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          events: {
            legendClick(chartContext, seriesIndex) {
              toggleSelected(series[seriesIndex].key);
            },
          },
        },
        xaxis: {
          categories: sortedMonths,
        },
        yaxis: {
          decimalsInFloat: 0,
          max: maxMonthSumRounded,
          min: 0,
          forceNiceScale: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '12px',
                },
              },
            },
          },
        },
        dataLabels: {
          formatter(val) {
            return val.toFixed(2);
          },
          style: {
            fontSize: '10px',
          },
        },
        annotations: {
          yaxis: annotations,
        },
        responsive: [{
          breakpoint: 1280,
          options: getStyleOptionsWithFontSize(
            8,
          ),
        }, {
          breakpoint: 1000,
          options: getStyleOptionsWithFontSize(
            8,
          ),
        }],
      }}
      series={series}
    />
  );
}

export default IncomeChart;
