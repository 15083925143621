import React from 'react';

import styles from './loader.module.scss';

export default function () {
  return (
    <div className={styles.container}>
      <div className={styles.loader} data-cy="loader" />
    </div>
  );
}
