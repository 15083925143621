const replaceTokens = (target, mapper) => {
  Object.keys(target).forEach((k) => {
    const value = target[k];
    if (typeof value === 'object') return replaceTokens(value, mapper);
    // eslint-disable-next-line no-param-reassign
    target[k] = Object.prototype.hasOwnProperty.call(mapper, value) ? mapper[value] : value;
    return true;
  });
};

export default (obj, functionsMapper) => {
  const object = JSON.parse(JSON.stringify(obj));
  replaceTokens(object, functionsMapper);
  return object;
};
