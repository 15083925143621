import { post } from 'aws-amplify/api';
import getCurrentAuthSession from '../utils/get-current-auth-session';

export default async (apiName, payload = {}) => {
  const { idToken } = await getCurrentAuthSession();
  try {
    const request = post({
      apiName,
      path: '/',
      options: { body: { ...payload, idToken } },
    });
    const { body } = await request.response;
    return body.json();
  } catch (error) {
    console.error(`Error in API: unable to call ${apiName}`, error);
    throw error;
  }
};
